import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import taste from 'sources/images/taste-2.jpg'
import care from 'sources/images/care-2.jpg'
import wellbeing from 'sources/images/wellbeing-3.jpg'
import company from 'sources/images/company.jpg'

const slides = [
  {
    title: 'Taste',
    src: taste,
    color: '#6C7B67',
    desc: (
      <p>
        <strong>Our 2030 Taste target</strong>
        <br />
        <br />
        Make sustainable eating the preferred choice.
      </p>
    ),
  },
  {
    title: 'Care',
    src: care,
    color: '#B4A0A0',
    desc: (
      <p>
        <strong>Our 2030 Care target</strong>
        <br />
        <br />
        Make clothes last twice as long with half the environmental impact.
      </p>
    ),
  },
  {
    title: 'Wellbeing',
    src: wellbeing,
    color: '#797487',
    desc: (
      <p>
        <strong>Our 2030 Wellbeing target</strong>
        <br />
        <br />
        Make homes healthier and more
        <br />
        sustainable through smart
        <br />
        solutions for air, water and
        <br />
        floors
      </p>
    ),
  },
  {
    title: 'Company',
    src: company,
    color: '#7B8A9C',
    desc: (
      <p>
        <strong>Our 2030 Company target</strong>
        <br />
        <br />
        Make our business circular
        <br />
        and climate neutral.
      </p>
    ),
  },
]

export default class InnovationsSlider extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 10000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/innovations/initiatives')
    }
  }

  onEnded = () => {
    this.setState({ video: false })
  }

  render = () => {
    const { index, video } = this.state

    return (
      <div className={styles.container} onClick={this.onNextSlide}>
        <Header
          title={slides[index].title}
          offsetTop={slides[index].offsetTop || 0}
          backTo="/menu/innovations/initiatives"
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={cn(styles.slide, styles[`slide${i}`])} key={i}>
              {slide.src && (
                <div className={cn(slide.imageClassName)}>
                  <img src={slide.src} className={styles.image} alt={slide.title} />
                </div>
              )}
              {slide.video && (
                <div className={cn(styles.videoWrapper, !video && styles.videoStopped)} onClick={this.onClickVideo}>
                  <video className={styles.video} ref={`video-${i}`} onEnded={this.onEnded} poster={slide.preview}>
                    <source src={slide.video} type="video/mp4" />
                  </video>
                </div>
              )}

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div className={cn(index === i && styles.activeDot)} onClick={e => this.onChangeIndex(i, e)} key={i} />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
