import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import electrolux from 'sources/images/electrolux.png'

export default function Innovations(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu" title="Innovations & Initiatives" offsetTop={-50} />

      <img src={electrolux} className={styles.electrolux} alt="Electrolux" />

      <p className={styles.desc}>
        We aim for a more modern and mindful way of eating, thoughtful ways to care for your wardrobe, and an
        effortlessly clean, comfortable and healthy home for you and your family.
        <Link to="/menu/innovations/innovations">
          View innovations <span className="fa">&#xf054;</span>
        </Link>
        The Better Living Program
        <Link to="/menu/innovations/initiatives">
          View initiative <span className="fa">&#xf054;</span>
        </Link>
      </p>
    </div>
  )
}
