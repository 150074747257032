import React, { useEffect } from 'react'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
// import brandIdea from 'sources/images/brand-idea.jpg'

export default function BrandStory(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/our-brand/who-we-are" title="Brand story" />

      <p className={styles.text}>
        No one knows what the future will bring. But we know one thing: what we do today, will shape tomorrow. Most of
        us understand that we can’t keep living the way we do, but it’s hard knowing where to start. We believe that a
        solution for a sustainable future is right in front of us. Where most of our days begin and end. Where we feel
        loved and where we love. Where we raise families and celebrate with friends. Where every single touch, feel and
        smell means we’re in the right place. At home. That’s where we can make a difference. We understand life at home
        and its everyday challenges, big and small. Our role is to help people along the way – everything we do is done
        to make their lives effortless, enriching and more sustainable. If all of us make small changes in our everyday
        lives, we can make a big difference. Not just for the home we live in, but for the home we all share. Whether
        it’s making food taste better, last longer and prevent it from being wasted. Or helping make clothes last longer
        and be less of a burden for our planet. Or making the air we breathe at home cleaner and healthier. We believe
        that the best way to take care of our future, is to care about how we live today.
        <br />
        <br />
        <b>For better living. From Sweden.</b>
      </p>
    </div>
  )
}
