import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'
import { VIDEOS } from 'containers/Home'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import demographics from 'sources/images/new-demographics.jpg'
import family from 'sources/images/family.jpg'
import care3 from 'sources/images/care-3.jpg'
import taste from 'sources/videos/Taste.mp4'
import care from 'sources/videos/Care.mp4'
import wellbeing from 'sources/images/wellbein-4.jpg'

export default class WhoLivesHereMore extends Component {
  state = {
    index: 0,
  }
  videos = {}
  slides = [
    {
      title: 'Demographics',
      src: demographics,
      desc: (
        <p>
          Living sustainably is something the Conscious Explorers find very important – and while they want to
          experience a lot, they always want to do the right thing. But doing the right thing, or even knowing what the
          right thing to do is, is not always easy, even for a savvy audience. This creates an opportunity for us to
          support with truly meaningful solutions.
        </p>
      ),
    },
    {
      title: 'Taste',
      src: family,
      desc: (
        <p className={styles.list}>
          <b>CONSCIOUS EXPLORERS:</b>
          <br />
          <br />
          <ul>
            <li>Want to drive positive change</li>
            <li>Enjoy cooking, for friends & family</li>
            <li>Already aware of the impact food has on the planet</li>
            <li>Interested in sustainable eating & cooking</li>
            <li>Always looking for new inspiration</li>
            <li>Motivated, but overwhelmed by contradictory information</li>
          </ul>
        </p>
      ),
    },
    {
      title: 'Care',
      src: care3,
      desc: (
        <p className={styles.list}>
          <b>CONSCIOUS EXPLORERS:</b>
          <br />
          <br />
          <ul>
            <li>Want to drive positive change</li>
            <li>Clothing is an expression of individual style</li>
            <li>Already aware of the impact of care techniques on clothes</li>
            <li>Interested in sustainable fashion, including repair and second-hand</li>
            <li>Motivated, but lack some ability regarding care</li>
          </ul>
        </p>
      ),
    },
    {
      title: 'Wellbeing',
      src: wellbeing,
      desc: (
        <p className={styles.list}>
          <b>CONSCIOUS EXPLORERS:</b>
          <br />
          <br />
          <ul>
            <li>Want to drive positive change</li>
            <li>See home as a tool to contribute to a better world</li>
            <li>Healthy living – affected by home environment</li>
            <li>Cleans on needs basis rather than routine</li>
            <li>Technology needs to add value – skeptical and impatient</li>
            <li>Values being close to nature</li>
            <li>Home as a safe harbor</li>
          </ul>
        </p>
      ),
    },
  ]

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 20000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      /*    if (index !== 1 && index !== 2) {
        this.setInterval()
        this.videos[1].pause()
        this.videos[2].pause()
      } else {
        clearInterval(this.interval)
        idleActions.unset()

        if (index !== 1) {
          this.videos[1].pause()
          this.videos[1].currentTime = 0
        } else if (index !== 2) {
          this.videos[2].pause()
          this.videos[2].currentTime = 0
        }

        this.videos[index].play()
      }*/
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < this.slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/who-lives-here')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header
          title={this.slides[index].title}
          backTo="/menu/who-lives-here"
          offsetTop={this.slides[index].offsetTop || 0}
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {this.slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              {slide.src && <img src={slide.src} alt={slide.title} />}
              {slide.video && <video src={slide.video} loop ref={node => (this.videos[i] = node)} />}

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {this.slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  idleActions.set()
                  this.onChangeIndex(i, e)
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
