import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import wirelessFood from 'sources/images/wireless-food.jpg'
import steamPro from 'sources/images/steam-pro.jpg'
import autoDoseWashing from 'sources/images/auto-dose-washing.jpg'
import pureF9 from 'sources/images/pure-f9.jpg'

const slides = [
  {
    title: (
      <span>
        Electrolux Wireless
        <br />
        Food Sensor Probe
      </span>
    ),
    offsetTop: -50,
    src: wirelessFood,
    imgMarginTop: 155,
    desc: (
      <p>
        Whether you’re griddling, simmering or exploring totally new ideas, the SensePro® hob gives you confidence.
        Discover the world’s first hob with wireless and battery-less food sensor.
      </p>
    ),
  },
  {
    title: (
      <span>
        Electrolux SteamPro
        <br />
        Oven with Steamify
      </span>
    ),
    offsetTop: -50,
    src: steamPro,
    imgMarginTop: 15,
    desc: (
      <p>
        Minimise food waste by easily reheating leftovers with the Steam Regeneration Function. The perfect amount of
        steam is used to heat and revive the food without drying it out. Unlike microwaves steam penetrates evenly,
        preserving the food’s colour, vitamins and flavours.
      </p>
    ),
  },
  {
    title: (
      <span>
        FreshScent
        <br />
        Steam Programme
      </span>
    ),
    offsetTop: -50,
    src: autoDoseWashing,
    imgMarginTop: 100,
    desc: (
      <p>
        Fresh clothes with 96% less water*
        <br />
        *Use steam to refresh clothes and save more than 40l of water per cycle, tested on 1kg delicate garments using
        the FreshScent programme compared to using the Delicates programme.
      </p>
    ),
  },
  {
    title: (
      <span>
        Well Q7 Cordless
        <br />
        Vacuum Cleaner
      </span>
    ),
    offsetTop: -50,
    src: pureF9,
    imgMarginTop: 150,
    desc: (
      <p>
        The Well Q7 makes cleaning multiple surfaces, or those hard to reach areas, quicker and more effortless than
        ever. Its ergonomic design offers better reach under furniture and improved cleaning comfort.
      </p>
    ),
  },
]

export default class InnovationsSlider extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 10000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/innovations')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header
          titleNode={slides[index].title}
          backTo="/menu/innovations"
          offsetTop={slides[index].offsetTop || 0}
          dark
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              {slide.desc}

              <img src={slide.src} alt={slide.title} style={{ marginTop: slide.imgMarginTop || 0 }} />
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  idleActions.set()
                  this.onChangeIndex(i, e)
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
