import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function Menu(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.blocks}>
        <Animated animationIn="fadeInRight" animateOnMount>
          <Link to="/menu/our-brand" className={styles.block}>
            <span>Our brand</span>
          </Link>
        </Animated>
        <Animated animationIn="fadeInLeft" animateOnMount>
          <Link to="/menu/who-lives-here" className={styles.block}>
            <span>Who lives here</span>
          </Link>
        </Animated>
        <Animated animationIn="fadeInRight" animateOnMount>
          <Link to="/menu/innovations" className={styles.block}>
            <span>
              INNOVATIONS
              <br />& Initiatives
            </span>
          </Link>
        </Animated>
      </div>
    </div>
  )
}
