import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Slider from 'react-slick'
import cn from 'classnames'
import history from 'utils/history'
import { message } from 'antd'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import electrolux from 'sources/images/electrolux.png'
import brandVideo from 'sources/videos/BrandVideo.mp4'
import taste from 'sources/videos/Taste.mp4'
import care from 'sources/videos/Care.mp4'

const toUpload = {
  brandVideo,
  taste,
  care,
}

class Videos {
  constructor() {
    this.init()
  }

  _video = {}

  async init() {
    // let msg = message.loading('Downloading ...', 0)

    try {
      for (const videoName of Object.keys(toUpload)) {
        console.info('Downloading', videoName, toUpload[videoName])
        const video = await fetch(toUpload[videoName])
        const file = await video.blob()
        console.info('Done')

        this._video[videoName] = URL.createObjectURL(file)
      }

      console.log('Ready')
      // message.success('All videos are downloaded', 10)
    } catch (e) {
      console.error(e)
      // message.error(e.message, 10)
    } finally {
      // msg()
    }
  }

  get video() {
    return this._video
  }
}

export const VIDEOS = new Videos()

const slides = [{ title: 'Our brand' }, { title: 'Who lives here' }, { title: 'Innovations & initiatives' }]

export default class Home extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.unset()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 10000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      this.onChangeIndex(0)
    }
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={() => history.push('/menu')}>
        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={cn(styles.slide, styles[`slide${i + 1}`])} key={i}>
              {/* <img src={slide.src} className={styles.image} alt={slide.title} /> */}
              <h2 className={styles.title}>{slide.title}</h2>
            </div>
          ))}
        </Slider>

        <h1 className={styles.crossTitle}>WELCOME TO THE WORLD OF</h1>
        <img className={styles.aeg} src={electrolux} alt="Electrolux" />

        <div className={styles.trigger}>
          <div className={styles.hand} />
        </div>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div className={cn(index === i && styles.activeDot)} onClick={e => this.onChangeIndex(i, e)} key={i} />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
