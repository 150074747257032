import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import NewSwedishValue from 'sources/images/new-swedish-value.jpg'
import swedishValue from 'sources/images/swedish-value.jpg'
import sustainability from 'sources/images/new-sustainability.jpg'

const slides = [
  {
    title: (
      <span className={styles.title}>
        <strong>Most human-centric experience</strong>
      </span>
    ),
    offsetTop: -30,
    src: NewSwedishValue,
    desc: (
      <div>
        <b>Making life at home more effortless and sustainable</b>
        <br />
        <br />
        <p>
          Everything we do aims to solve real challenges, for real people, in the real world. And for a company like us,
          it’s natural to start with the most precious place there is: home. With the ambition to improve the lives of
          the people living there. Our purpose is to make home life more effortless, enriching and sustainable, by
          putting consumers’ home life needs at the center of our innovation. We work with other companies to find more
          sustainable solutions, develop new ways of owning and using our products and create smart software solutions
          to make everyday life accessible to more people.
        </p>
      </div>
    ),
  },
  {
    title: (
      <span className={styles.title}>
        <strong>Leader in sustainability</strong>
      </span>
    ),
    offsetTop: -30,
    src: swedishValue,
    desc: (
      <div>
        <b>Making better solutions accessible to more people What we all do today will shape tomorrow.</b>
        <br />
        <br />
        <p>
          Not least what we do in our homes. That’s why we’re committed to make sustainable solutions more accessible to
          help people live more sustainably - whether it’s making clothes last longer, food leftovers taste better, or
          the air we breathe cleaner.
          <br />
          <br />
          With everything we do, we improve circularity and efficiency in the use of resources. We believe small,
          everyday changes can make a big difference.
        </p>
      </div>
    ),
  },
  {
    title: (
      <span className={styles.title}>
        <strong>Scandinavian design</strong>
      </span>
    ),
    offsetTop: -30,
    src: sustainability,
    desc: (
      <div>
        <b>Beauty with a meaning. And function with a story.</b>
        <br />
        <br />
        <p>
          Design is a true testimony to our Human Centric Scandinavian design heritage, based on its founding principles
          of usability and simplicity, contrasted with humanity, grace & beauty, making experiences more meaningful &
          memorable. While timeless and rooted in high craftmanship, it is also experimental and always at the forefront
          of change with the objective to improve homelife.
          <br />
          <br />
          When home is the most important place, it is only natural to put equal importance to function and aesthetics.
        </p>
      </div>
    ),
  },
]

export default class BrandPillars extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 30000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/our-brand')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header title={slides[index].title} backTo="/menu/our-brand" offsetTop={slides[index].offsetTop || 0} />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              <img src={slide.src} alt={slide.title} />

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  idleActions.set()
                  this.onChangeIndex(i, e)
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
