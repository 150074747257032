import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function InitiativePreview(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu/innovations" title="The Better Living Program" offsetTop={-50} />

      <p className={styles.desc}>
        For over 100 years Electrolux has been determined to make the everyday better for millions of people around the
        world. Sustainability has always been part of our DNA. Perhaps that's why we have been industry leaders in
        sustainability for over a decade. But we can and will do more.
        <br />
        <br />
        We believe actions speak louder than words. That is why we launched our most important initiative ever: The
        Better Living Program. 100 actions to shape better and more sustainable living.
        <br />
        <br />
        We have four new business targets for 2030 and our Better Living Program defines the actions we need to take in
        order to meet these targets.
      </p>

      <Animated animationIn="fadeInUp" animateOnMount>
        <Link to="/menu/innovations/initiatives/more" className={styles.button}>
          2030 Targets
        </Link>
      </Animated>
    </div>
  )
}
