import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import taste from 'sources/images/new-taste.jpg'
import care from 'sources/images/new-care.jpg'
import wellbeing from 'sources/images/new-wellbeing.jpg'

const slides = [
  {
    title: 'Taste',
    src: taste,
    desc: (
      <p>
        New ways and tools to eat tastier, healthier and more sustainably together with your family and friends. A more
        modern and mindful way to eat.
      </p>
    ),
  },
  {
    title: 'Care',
    src: care,
    desc: (
      <p>
        Always better solutions to care for your favorite garments so they stay new for longer so you can wear them as
        often as you like. Thoughtful care for you and
        <br />
        for the environment.
      </p>
    ),
  },
  {
    title: 'Wellbeing',
    src: wellbeing,
    desc: (
      <p>
        We show the way with a holistic and sustainable approach to wellbeing. Creating solutions that together
        contribute to making it easy and pleasurable for you to maintain a healthy home to thrive in.
      </p>
    ),
  },
]

export default class Taste extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 20000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/menu/our-brand')
    }
  }

  onClick = () => {
    idleActions.set()
    this.onNextSlide()
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header title={slides[index].title} backTo="/menu/our-brand" offsetTop={slides[index].offsetTop || 0} />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              <img src={slide.src} alt={slide.title} />

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  idleActions.set()
                  this.onChangeIndex(i, e)
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
