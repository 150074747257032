import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import consciousExplorers from 'sources/images/new-conscious-explorers.jpg'

export default function WhoLivesHere(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header backTo="/menu" title="The Conscious Explorers" offsetTop={-50} />

      <img src={consciousExplorers} className={styles.image} alt="Conscious explorers" />

      <p className={styles.desc}>
        <strong>A premium, progressive audience</strong>
        <br />
        <br />
        Being a premium and progressive group, they have a desire to drive positive change for their loved ones,
        humanity and the environment. Their everyday lives revolve around family, friends and often a fast paced work
        life. Their curiosity leads them to try new and exciting experiences. They are willing and able to pay for
        meaningful, sustainable innovations and brands that make it effortless to enrich their experiences.*
        <br />
        <br />
        <Link to="/menu/who-lives-here/more">
          Explore more <span className="fa">&#xf054;</span>
        </Link>
      </p>

      <strong className={styles.sourceGlobal}>*Source global SIGMA research and Global Segmentation study</strong>
    </div>
  )
}
