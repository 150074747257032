import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import history from 'utils/history'

import styles from './styles.module.scss'
import homeIcon from 'sources/images/home-breadcrumb.png'
import homeBlueIcon from 'sources/images/home-blue-breadcrumb.png'

function Header(props) {
  const { title = '', backTo, dark, offsetTop, metric, paddingLeft = 0, paddingRight = 45, titleNode } = props

  const onClickBack = e => {
    e.stopPropagation()

    history.push(backTo)
  }

  const onClickHome = e => {
    e.preventDefault()
    e.stopPropagation()

    history.push('/menu')
  }

  return (
    <div className={cn(styles.header, dark && styles.dark)}>
      <nav>
        <a href="/menu" onClick={onClickHome} className={styles.home}>
          <img src={dark ? homeBlueIcon : homeIcon} alt="home" />
        </a>
      </nav>

      <div className={styles.bottom} style={{ top: offsetTop || 0 }}>
        <span className={styles.back} onClick={onClickBack} />
        <h1 className={cn(styles.title, metric && styles.titleMetric)} style={{ paddingLeft, paddingRight }}>
          {titleNode ? titleNode : title}
        </h1>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  titleNode: PropTypes.node,
  backTo: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  offsetTop: PropTypes.number,
  metric: PropTypes.bool,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
}

export default Header
